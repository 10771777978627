import React from "react";
import FirebaseUI from "../../../components/FirebaseUI";

const SignIn = () => {
  return (
    <>
      <div className="col-md-12">
        <div className="text-center col-md-12">
          <div className="card">
            <p></p>
            <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
            <div className="card-body">
              <FirebaseUI />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
