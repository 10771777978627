import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import * as firebaseui from "firebaseui";
import { FirebaseAuth, FirebaseAnalytics } from "../FirebaseAuth/firebase";
import firebase from "firebase/app";
import { userSignIn, userVerifyEmail } from "../../libs/user";
import { SidebarContext } from "../templates/PublicTemplate";

const FirebaseUI = () => {
  const [signInSuccess, setSignInSuccess] = useState(null);
  const [userData, setUserData] = useState({});

  // sidebar context to disable when loading
  const { setSidebar } = useContext(SidebarContext);

  // Configure FirebaseUI.
  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        setSidebar("disabled"); // disable sidebar while we create account etc. to avoid users trying to create API Key
        userSignIn((result) => {
          // if user uses email/password authentication, then need to wait for email verification first
          var user = authResult.user;
          setUserData(user);
          if (!user.emailVerified) {
            if (authResult.additionalUserInfo.isNewUser) {
              console.log("new signin");
              const email = user.providerData[0].email;
              userVerifyEmail(email).then((result) => {
                if (result === "pass") {
                  user.sendEmailVerification({
                    url: process.env.REACT_APP_EMAIL_VERIFICATION_URL,
                  });
                }
              });
            }
            setSidebar("enabled");
            setSignInSuccess("pendingVerification");
          } else if (result) {
            setSidebar("enabled");
            setSignInSuccess("true");
            // fire google analytics to record successful login + login method
            FirebaseAnalytics.logEvent(firebase.analytics.EventName.LOGIN, {
              method: authResult.additionalUserInfo.providerId,
            });
            window.location = "/"; // redirect to root page after which we'll redirect to account page for logged in user
          } else {
            setSidebar("enabled");
            setSignInSuccess("false");
          }
        });
        return false;
      },
      uiShown: function () {
        document.getElementById("loader").style.display = "none";
      },
    },
    signInSuccessUrl: "/",
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // Required to enable ID token credentials for this provider.
        // This can be obtained from the Credentials page of the Google APIs
        // console. Use the same OAuth client ID used for the Google provider
        // configured with GCIP or Firebase Auth.
        clientId: process.env.REACT_APP_FIREBASE_AUTH_GOOGLE_CLIENT_ID,
      },
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod:
          firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
      },
    ],
    // Required to enable one-tap sign-up credential helper.
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
  };

  return (
    <>
      {signInSuccess === "true" && (
        <i className="fa fa-spinner fa-5x fa-spin" />
      )}
      {signInSuccess === null && (
        <>
          <div id="sign-in" className="SignIn">
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={FirebaseAuth.auth()}
            />
          </div>
          <div id="loader">
            <i className="fa fa-spinner fa-5x fa-spin" />
          </div>
        </>
      )}
      {signInSuccess === "false" && (
        <div className="text-center">
          <i className="fa fa-5x text-warning"></i>
          <h1 className="text-responsive-large">Server Error</h1>
          <p>Oops, something went wrong, please try again.</p>
          <Link to="/">Home</Link>
        </div>
      )}
      {signInSuccess === "pendingVerification" && (
        <div className="text-center">
          <i className="fa fa-5x text-info"></i>
          <h1 className="text-responsive-large">Pending Email Verification</h1>
          <p>
            Please check your email inbox to verify the email address. Refresh
            this page after you have verified your email address.
          </p>
          <p>
            If you still haven't received the verification email after a few
            minutes,{" "}
            <strong>please check your Junk or Spam Email folder</strong>.
          </p>
          <Link to="/" className="btn btn-success mr-2">
            I've verified my email
          </Link>
          <button
            className="btn btn-primary mr-2"
            onClick={(e) => {
              e.preventDefault();
              console.log("re-sending verification email");
              const email = userData.providerData[0].email;
              userVerifyEmail(email).then((result) => {
                if (result === "pass") {
                  userData.sendEmailVerification({
                    url: process.env.REACT_APP_EMAIL_VERIFICATION_URL,
                  });
                }
              });
            }}
          >
            Resend verification email
          </button>
        </div>
      )}
      <div>
        <small className="text-muted">One email account per customer.</small>
      </div>
      <div>
        <Link to="/terms">Terms of Use / Privacy Policy</Link>
      </div>
    </>
  );
};

export default FirebaseUI;
