import React from "react";
import { Link } from "react-router-dom";

const PublicUserMenu = () => {
  return (
    <>
      <li className="c-header-nav-item" id="public-user-menu">
        <Link to="/signin">
          <i className="fas fa-sign-in-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Sign In / Sign Up
        </Link>
      </li>
    </>
  );
};

export default PublicUserMenu;
