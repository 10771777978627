import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { AuthProvider } from "./components/FirebaseAuth";

import PublicRouter from "./components/routers/PublicRouter";
import PublicTemplate from "./components/templates/PublicTemplate";
import ActiveAccountTemplate from "./components/templates/ActiveAccountTemplate";

import AuthRouter from "./components/routers/AuthRouter";

import Docs from "./pages/public/Docs";
import SignIn from "./pages/public/SignIn";
import Home from "./pages/auth/Home";
import PageNotFound from "./pages/public/PageNotFound";
import Pricing from "./pages/public/Pricing";
import Terms from "./pages/public/Terms";
import AppTemplate from "./components/templates/AppTemplate";
import UserProfile from "./pages/auth/user/UserProfile";
import UpdateEmail from "./pages/auth/user/UpdateEmail";
import UpdateName from "./pages/auth/user/UpdateName";
import VerifyEmail from "./pages/auth/user/VerifyEmail";
import UpdatePassword from "./pages/auth/user/UpdatePassword";
import ViewLogs from "./pages/auth/user/ViewLogs";
import Plans from "./pages/auth/accounts/Plans";
import NewAccount from "./pages/auth/accounts/NewAccount";

// load stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Overview from "./pages/auth/accounts/Overview";
import UserRole from "./pages/auth/accounts/UserRole";
import PaymentList from "./pages/auth/accounts/PaymentList";
import PaymentMethod from "./pages/auth/accounts/PaymentMethod";
import DeleteAccount from "./pages/auth/accounts/DeleteAccount";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_API_KEY);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <AuthProvider>
        <Router>
          <Switch>
            <PublicRouter
              exact
              path="/"
              component={Home}
              template={PublicTemplate}
              title="Home"
            />
            <AuthRouter
              exact
              path="/account/:accountId/billing/plan"
              component={Plans}
              template={ActiveAccountTemplate}
              title="Select Plan"
              role="admin"
            />
            <AuthRouter
              exact
              path="/account/:accountId/billing/payment-method"
              component={PaymentMethod}
              template={ActiveAccountTemplate}
              title="Update Payment Method"
              role="admin"
            />
            <AuthRouter
              exact
              path="/account/:accountId/billing/delete"
              component={DeleteAccount}
              template={ActiveAccountTemplate}
              title="Delete Account"
              role="admin"
            />
            <AuthRouter
              exact
              path="/account/:accountId/users/change/:userId"
              component={UserRole}
              template={ActiveAccountTemplate}
              title="Change Role"
              role="admin"
            />
            <AuthRouter
              exact
              path="/account/:accountId/billing"
              component={PaymentList}
              template={ActiveAccountTemplate}
              title="Billing"
              role="admin"
            />
            <AuthRouter
              exact
              path="/account/:accountId/"
              component={Overview}
              template={ActiveAccountTemplate}
              title="Overview"
              role="*"
            />
            <AuthRouter
              exact
              path="/new-account"
              component={NewAccount}
              template={AppTemplate}
              title="Create New Account"
            />
            <AuthRouter
              exact
              path="/account/:accountId/profile"
              component={UserProfile}
              title="User Profile"
              template={ActiveAccountTemplate}
              role="admin"
            />
            <AuthRouter
              exact
              path="/user/profile"
              component={UserProfile}
              title="User Profile"
              template={AppTemplate}
            />
            <AuthRouter
              exact
              path="/account/:accountId/profile/update-email"
              component={UpdateEmail}
              title="Change Your Email"
              template={ActiveAccountTemplate}
              role="admin"
            />
            <AuthRouter
              exact
              path="/account/:accountId/profile/update-name"
              component={UpdateName}
              title="Change Your Name"
              template={ActiveAccountTemplate}
              role="admin"
            />
            <AuthRouter
              exact
              path="/account/:accountId/profile/verify-email"
              component={VerifyEmail}
              title="Verify Your Email"
              template={ActiveAccountTemplate}
              role="admin"
            />
            <AuthRouter
              exact
              path="/account/:accountId/profile/update-password"
              component={UpdatePassword}
              template={ActiveAccountTemplate}
              title="Change Your Password"
              role="admin"
            />
            <AuthRouter
              exact
              path="/account/:accountId/log"
              component={ViewLogs}
              title="View Activity Logs"
              template={ActiveAccountTemplate}
              role="admin"
            />
            <AuthRouter
              exact
              path="/user/log"
              component={ViewLogs}
              title="View Activity Logs"
              template={AppTemplate}
            />
            <PublicRouter
              exact
              path="/docs"
              component={Docs}
              template={PublicTemplate}
              title="Lecto Translation API Documentation"
            />
            <PublicRouter
              exact
              path="/signin"
              component={SignIn}
              template={PublicTemplate}
              title="Sign In"
            />
            <PublicRouter
              exact
              path="/pricing"
              component={Pricing}
              template={PublicTemplate}
              title="Pricing"
            />
            <PublicRouter
              exact
              path="/terms"
              component={Terms}
              template={PublicTemplate}
              title="Terms Of Use"
            />
            <PublicRouter
              component={PageNotFound}
              template={PublicTemplate}
              title="Page Not Found"
            />
          </Switch>
        </Router>
      </AuthProvider>
    </Elements>
  );
}

export default App;
