import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import SidebarLink from "../SidebarLink";
import { AuthContext } from "../../FirebaseAuth";

const AccountMenu = () => {
  const { accountId } = useParams();

  const { userData } = useContext(AuthContext);

  useEffect(() => {
    document.querySelectorAll(".c-sidebar").forEach((element) => {
      window.coreui.Sidebar._sidebarInterface(element);
    });
  });

  return (
    <ul className="c-sidebar-nav ps ps--active-y">
      <li key="sidebar-referral-title" className="c-sidebar-nav-title">
        Referral Program
      </li>
      <li key="sidebar-referral-link" className="c-sidebar-nav-item">
        <SidebarLink
          className={
            "c-sidebar-nav-link" +
            (window.location.pathname.startsWith("/referral") ? " active" : "")
          }
          to={{
            pathname: "https://lecto.firstpromoter.com/",
          }}
          target="_blank"
          rel="nofollow"
        >
          <i className="c-sidebar-nav-icon fa fa-handshake"></i> Referral
          Program
        </SidebarLink>
      </li>
      <li key="sidebar-account-title" className="c-sidebar-nav-title">
        Account
      </li>
      <li key="sidebar-account-link" className="c-sidebar-nav-item">
        <SidebarLink
          className={
            "c-sidebar-nav-link" +
            (window.location.pathname === "/account/" + accountId + "/"
              ? " active"
              : "")
          }
          to={"/account/" + accountId + "/"}
        >
          <i className="c-sidebar-nav-icon fa fa-book"></i>API Key & Docs
        </SidebarLink>
      </li>
      {userData.currentAccount.role === "admin" && (
        <>
          <li key="sidebar-settings-title" className="c-sidebar-nav-title">
            Settings
          </li>
          <li key="sidebar-pricing-link" className="c-sidebar-nav-item">
            <SidebarLink
              className={
                "c-sidebar-nav-link" +
                (window.location.pathname.startsWith(
                  "/account/" + accountId + "/billing/plan"
                )
                  ? " active"
                  : "")
              }
              to={"/account/" + accountId + "/billing/plan"}
            >
              <i className="c-sidebar-nav-icon fa fa-credit-card"></i>Pricing
            </SidebarLink>
          </li>
          <li key="sidebar-billing-link" className="c-sidebar-nav-item">
            <SidebarLink
              className={
                "c-sidebar-nav-link" +
                (window.location.pathname.startsWith(
                  "/account/" + accountId + "/billing"
                )
                  ? " active"
                  : "")
              }
              to={"/account/" + accountId + "/billing"}
            >
              <i className="c-sidebar-nav-icon fa fa-money-bill"></i>Billing
            </SidebarLink>
          </li>
          <li key="sidebar-profile-link" className="c-sidebar-nav-item">
            <SidebarLink
              className={
                "c-sidebar-nav-link" +
                (window.location.pathname.startsWith(
                  "/account/" + accountId + "/profile"
                )
                  ? " active"
                  : "")
              }
              to={"/account/" + accountId + "/profile"}
            >
              <i className="c-sidebar-nav-icon fa fa-user"></i> Profile
            </SidebarLink>
          </li>
          <li key="sidebar-activity-link" className="c-sidebar-nav-item">
            <SidebarLink
              className={
                "c-sidebar-nav-link" +
                (window.location.pathname.startsWith(
                  "/account/" + accountId + "/log"
                )
                  ? " active"
                  : "")
              }
              to={"/account/" + accountId + "/log"}
            >
              <i className="c-sidebar-nav-icon fa fa-list"></i> Activity Logs
            </SidebarLink>
          </li>
          <li key="sidebar-support-title" className="c-sidebar-nav-title">
            Support
          </li>
          <li key="sidebar-chat-link" className="c-sidebar-nav-item">
            <SidebarLink
              className={
                "c-sidebar-nav-link" +
                (window.location.pathname.startsWith("/chat") ? " active" : "")
              }
              to={{
                pathname: `https://go.crisp.chat/chat/embed/?website_id=${window.CRISP_WEBSITE_ID}&user_email=${userData.currentAccount.name}`,
              }}
              target="_blank"
              rel="nofollow"
            >
              <i className="c-sidebar-nav-icon fa fa-comment-alt"></i> Live Chat
            </SidebarLink>
          </li>
          <li key="sidebar-contact-link" className="c-sidebar-nav-item">
            <SidebarLink
              className={
                "c-sidebar-nav-link" +
                (window.location.pathname.startsWith("/contact")
                  ? " active"
                  : "")
              }
              to={{
                pathname:
                  "mailto:%73%75%70%70%6f%72%74%40%6c%65%63%74%6f%2e%61%69",
              }}
              target="_blank"
              rel="nofollow"
            >
              <i className="c-sidebar-nav-icon fa fa-envelope"></i> Contact Us
            </SidebarLink>
          </li>
          <li key="sidebar-status-link" className="c-sidebar-nav-item">
            <SidebarLink
              className={
                "c-sidebar-nav-link" +
                (window.location.pathname.startsWith("/status")
                  ? " active"
                  : "")
              }
              to={{
                pathname: "https://lecto.statuspage.io/",
              }}
              target="_blank"
              rel="nofollow"
            >
              <i className="c-sidebar-nav-icon fa fa-info"></i> System Status
            </SidebarLink>
          </li>
          <li key="sidebar-legal-title" className="c-sidebar-nav-title">
            Legal Stuff
          </li>
          <li key="sidebar-tos-link" className="c-sidebar-nav-item">
            <SidebarLink
              className={
                "c-sidebar-nav-link" +
                (window.location.pathname.startsWith("/terms") ? " active" : "")
              }
              to={"/terms"}
            >
              <i className="c-sidebar-nav-icon fa fa-gavel"></i> Terms of Use /
              Privacy Policy
            </SidebarLink>
          </li>
        </>
      )}
    </ul>
  );
};

export default AccountMenu;
