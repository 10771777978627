import React, { useState, useEffect } from "react";
import { RedocStandalone } from "redoc";
import { CopyBlock, monokai } from "react-code-blocks";
import { Helmet } from "react-helmet";
import FirebaseUI from "../../../components/FirebaseUI";
import firebase from "firebase/app";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";

const title = "Lecto Translation API Documentation";

const Docs = () => {
  const [signedOut, setSignedOut] = useState(true);
  const [showFirebaseUI, setShowFirebaseUI] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // redirect to home page for logged in user
        // which will then again redirect to the account page
        setSignedOut(false);
      }
    });

    // delayed loading of firebase ui (one-tap signin) in case we want to redirect signed in users
    setTimeout(() => {
      setShowFirebaseUI(true);
    }, 3000); // 3 seconds
  });

  return (
    <>
      {signedOut ? (
        <>
          <div className="list-group">
            <Helmet>
              <meta charSet="utf-8" />
              <title>{title}</title>
              <link rel="canonical" href="https://dashboard.lecto.ai" />
            </Helmet>
            {/* delayed loading of one-tap sign-in on this page without displaying sign-in element itself */}
            <div className="hidden-element">
              {showFirebaseUI && <FirebaseUI />}
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 text-center">
                <h1 className="h2">Lecto Translation API Dashboard</h1>
              </div>
              <div className="col-sm-12 col-md-12 text-center text-responsive-large">
                <p>
                  Try the Lecto Translation API with a Free API Key. No credit
                  card required! <a href="/signin">Sign In or Sign Up here.</a>
                </p>
                <p>
                  Take a look at our affordable{" "}
                  <a href="/pricing">Pricing options</a> or{" "}
                  <a
                    href="mailto:%73%75%70%70%6f%72%74%40%6c%65%63%74%6f%2e%61%69"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    get in touch with us
                  </a>{" "}
                  if you're interested in enterprise plans or licensing our API
                  for on-premise deployment.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 text-center mt-1 mb-1">
                <Link to={"/signin"} className="btn btn-success btn-lg mr-2">
                  Sign In for API Key
                </Link>
              </div>
              <div className="col-sm-12 col-md-6 text-center mt-1 mb-1">
                <Link
                  to={{
                    pathname: `https://go.crisp.chat/chat/embed/?website_id=${window.CRISP_WEBSITE_ID}`,
                  }}
                  target="_blank"
                  rel="nofollow"
                  className="btn btn-primary btn-lg mr-2"
                >
                  Need help? Chat with Us
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 order-md-1 text-center codeblock">
                <h3>Sample Text Translation Request</h3>
              </div>
              <div className="col-sm-12 col-md-6 order-md-12 text-left codeblock">
                <CopyBlock
                  text={`curl -X POST "${process.env.REACT_APP_TRANSLATE_API_URL}/v1/translate/text" \\
                      -H 'X-API-Key: <Sign-In-For-API-Key>' \\
                      -H 'Content-Type: application/json' \\
                      -H 'Accept: application/json' \\
                      --data-raw '{
                          "texts": ["Just try it mate.","What are you waiting for?"],
                          "to": ["pt-BR", "de"],
                          "from": "en"
                      }' --compressed`}
                  language="bash"
                  theme={monokai}
                  showLineNumbers={false}
                  codeBlock
                />
              </div>
              <div className="col-sm-12 col-md-6 order-md-7 text-center codeblock">
                <h3>Sample JSON Translation Request</h3>
              </div>
              <div className="col-sm-12 col-md-6 order-md-12 text-left codeblock">
                <CopyBlock
                  text={`curl -X POST "${process.env.REACT_APP_TRANSLATE_API_URL}/v1/translate/json" \\
                      -H 'X-API-Key: <Sign-In-For-API-Key>' \\
                      -H 'Content-Type: application/json' \\
                      -H 'Accept: application/json' \\
                      --data-raw '{
                        "to": ["pt-BR", "de"],
                        "from": "en",
                        "protected_keys": ["team.name"],
                        "json": "{\\"team\\":{\\"name\\":\\"Los Angeles Lakers\\",\\"player_summary\\":{\\"LeBron James\\":\\"Widely considered one of the greatest NBA players in history.\\",\\"Andre Drummond\\":\\"A two-time NBA All-Star, he has led the league in rebounding four times and also been named to the All-NBA Third Team.\\"}}}"
                    }' --compressed`}
                  language="bash"
                  theme={monokai}
                  showLineNumbers={false}
                  codeBlock
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 text-center mb-2">
                <Link
                  to={{
                    pathname:
                      "https://github.com/lecto-ai/docs/tree/main/examples",
                  }}
                  target="_blank"
                  rel="nofollow"
                  className="btn btn-primary mr-2"
                >
                  PHP/Python/NodeJS Code Examples on Github
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 text-center">
                <h3>API Documentation</h3>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="animated fadeIn">
              <div className="card">
                <div className="card-header">{title}</div>
                <div className="card-body">
                  <RedocStandalone
                    specUrl="/swagger.yaml"
                    options={{
                      nativeScrollbars: true,
                      theme: { colors: { primary: { main: "#597EFC" } } },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Redirect to="/"></Redirect>
        </>
      )}
    </>
  );
};

export default Docs;
