import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Sentry error reporting
Sentry.init({
  dsn:
    "https://e9541259d55d405e8f2eb4323a8b9022@o1146382.ingest.sentry.io/6215044",
  integrations: [new BrowserTracing()],

  // tracesSampleRate 0.1 == 10% performance data captured, errors will still be captured
  tracesSampleRate: 0.1,
});

// Workaround to make React work with Google Translate translation plugin, makes app a little slower
// Critical for us as a Translation related provider, users are from around the world.
// Without this fix, was seeing the billing plan page crash when the payment Call to action button was clicked
// Ref: https://github.com/facebook/react/issues/11538#issuecomment-417504600
// Sentry Error: https://sentry.io/organizations/lecto-ai-limited/issues/3071254484/?project=6215044&query=is%3Aunresolved
if (typeof Node === "function" && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error(
          "Cannot remove a child from a different parent",
          child,
          this
        );
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          "Cannot insert before a reference node from a different parent",
          referenceNode,
          this
        );
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  };
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
