import React from "react";

// PriceCTA component renders a price card call to action based on given plan
// Re-usable on Plans and Public Pricing pages
const PriceCTA = ({ plan, selectedPlan, onclickHandler, processing }) => {
  return (
    <div className="card-footer bg-white">
      {/* Render different calls to action for credits based plan v/s subscription plans */}
      {!plan.isMeteredSubscription && (
        <button
          type="button"
          id="buy-credits"
          className={
            plan.id !== selectedPlan.id
              ? "btn btn-block btn-outline-success"
              : "btn btn-block btn-success"
          }
          disabled={processing}
          onClick={() => {
            onclickHandler(plan);
          }}
        >
          {plan.id === selectedPlan.id && (
            <>
              <i className="fa fa-check"></i>{" "}
            </>
          )}
          {plan.current &&
            (plan.id !== selectedPlan.id ? "Top up" : "Selected")}
          {!plan.current &&
            (plan.id !== selectedPlan.id ? "Buy now" : "Selected")}
        </button>
      )}
      {plan.isMeteredSubscription &&
        (plan.current ? (
          <button
            type="button"
            id="buy-subscription"
            className="btn btn-block btn-secondary"
            disabled={true}
          >
            Current Plan
          </button>
        ) : (
          <button
            type="button"
            className={
              plan.id !== selectedPlan.id
                ? "btn btn-block btn-outline-success"
                : "btn btn-block btn-success"
            }
            disabled={processing}
            onClick={() => {
              onclickHandler(plan);
            }}
          >
            {plan.id === selectedPlan.id && (
              <>
                <i className="fa fa-check"></i>{" "}
              </>
            )}
            {plan.id !== selectedPlan.id ? "Subscribe now" : "Selected"}
          </button>
        ))}
    </div>
  );
};

export default PriceCTA;
