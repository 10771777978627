import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useReducer,
} from "react";
import yaml from "js-yaml";
import { RedocStandalone } from "redoc";
import ProgressBar from "react-animated-progress-bar";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { Link, Redirect, useLocation } from "react-router-dom";
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import { CopyBlock, monokai } from "react-code-blocks";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Loader from "../../../../components/Loader";
import Alert from "../../../../components/Alert";

const initialPlanState = {
  name: "",
  usageType: "",
  usageLimit: 0,
  usage: 0,
  usageRetrieved: false,
  usagePercentage: 0,
};

// use reducer to dynamically calculate usage percentage
function planReducer(state, action) {
  switch (action.type) {
    case "field":
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    case "usagePercentage":
      return {
        ...state,
        usagePercentage: (state.usage / state.usageLimit) * 100,
      };
    default:
      return state;
  }
}

const Overview = () => {
  const title = "Overview";

  // read dynamic query params
  const search = useLocation().search;
  const subscriptionQuery = new URLSearchParams(search).get("subscription");
  const pricingQuery = new URLSearchParams(search).get("pricing");

  const { userData } = useContext(AuthContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [apiKey, setApiKey] = useState("");
  const [apiKeyConfirm, setApiKeyConfirm] = useState(false);
  const [inSubmit, setInSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
  const [pricingRedirect, setPricingRedirect] = useState(false);

  // load from swagger yaml and inject API Key at runtime
  const [swaggerObject, setSwaggerObject] = useState(null);

  const [planState, dispatch] = useReducer(planReducer, initialPlanState);

  const mountedRef = useRef(true);

  const apiKeyCacheKey = "apiKey";

  const renderApiKeyFromLocalStorage = async () => {
    const cachedApiKey = localStorage.getItem(apiKeyCacheKey);
    if (cachedApiKey) {
      setApiKey(cachedApiKey);

      const response = await fetch("/swagger.yaml");
      const originalSwaggerYaml = await (await response.blob()).text();
      const swaggerYamlWithAPIKey = originalSwaggerYaml.replaceAll(
        "<API Key>",
        cachedApiKey
      );
      const swaggerObject = yaml.load(swaggerYamlWithAPIKey);
      setSwaggerObject(swaggerObject);
    }
  };

  useEffect(() => {
    // check query string for dynamic state info such as subscription success
    const checkSubscriptionSuccess = () => {
      if (subscriptionQuery === "success") {
        setSubscriptionSuccess(true);
      }
    };
    checkSubscriptionSuccess();

    const checkPricingRedirect = () => {
      if (pricingQuery === "true") {
        setPricingRedirect(true);
      }
    };
    checkPricingRedirect();

    renderApiKeyFromLocalStorage();
    window.addEventListener("storage", () => {
      // Whenever localStorage changes in another window
      // refetch latest api key value
      // for same window, must call this function explicitly
      renderApiKeyFromLocalStorage();
    });

    const getPlanDetails = () => {
      if (userData.currentAccount.planId) {
        FirebaseAuth.firestore()
          .collection("plans")
          .doc(userData.currentAccount.planId)
          .get()
          .then((planDoc) => {
            if (!mountedRef.current) return null;

            if (planDoc) {
              dispatch({
                type: "field",
                fieldName: "name",
                payload: planDoc.data().name,
              });
              dispatch({
                type: "field",
                fieldName: "usageType",
                payload: planDoc.data().usageType,
              });
              dispatch({
                type: "field",
                fieldName: "usageLimit",
                payload: planDoc.data().isMeteredSubscription
                  ? planDoc.data().usageLimit
                  : userData.currentAccount.creditsUsageLimit, // creditsUsageLimit for credits based plan
              });

              const getUsage = CloudFunctions.httpsCallable("getUsage");
              getUsage({
                accountId: userData.currentAccount.id,
              })
                .then((res) => {
                  if (!mountedRef.current) return null;
                  dispatch({
                    type: "field",
                    fieldName: "usage",
                    payload: res.data.usage,
                  });
                  dispatch({
                    type: "usagePercentage",
                  });
                  dispatch({
                    type: "field",
                    fieldName: "usageRetrieved",
                    payload: true,
                  });
                })
                .catch((ex) => {
                  if (!mountedRef.current) return null;
                  console.error(ex);
                });
            }
          })
          .catch((ex) => {
            if (!mountedRef.current) return null;
            console.error(ex);
          });
      }
    };
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/account/" + userData.currentAccount.id + "/",
        text: userData.currentAccount.name,
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);

    getPlanDetails();

    if (window.location.hostname === "localhost") {
      // can't decrypt using kms from local emulator, so skip it
      localStorage.setItem(apiKeyCacheKey, "Dummy localhost API Key");
      renderApiKeyFromLocalStorage();
    } else {
      const getAccountAPIKey = CloudFunctions.httpsCallable("getAccountAPIKey");
      getAccountAPIKey({
        accountId: userData.currentAccount.id,
      })
        .then((res) => {
          if (!mountedRef.current) return null;
          localStorage.setItem(apiKeyCacheKey, res.data.apiKey);
          renderApiKeyFromLocalStorage();
        })
        .catch((ex) => {
          if (!mountedRef.current) return null;
          console.error(ex);
        });
    }

    // report signed up customer email to First Promoter for Affiliate Tracking
    window.fpr("referral", {
      email: userData.currentAccount.name,
    });

    return () => {
      mountedRef.current = false;
    };
  }, [userData, setBreadcrumb, title, subscriptionQuery, pricingQuery]);

  return (
    <>
      {!pricingRedirect && (
        <div className="container-fluid">
          <div className="animated fadeIn">
            <div className="card">
              <div className="card-header">{title}</div>
              <div className="card-body">
                {subscriptionSuccess && (
                  <>
                    <Alert
                      type="success"
                      message="You have successfully signed up for Lecto Translation API! Begin by using your unique API Key and completing the sample requests below."
                      dismissible={true}
                      onDismiss={() => setSuccess(false)}
                    ></Alert>
                  </>
                )}
                {success ? (
                  <>
                    <Alert
                      type="success"
                      message="Your API Key has been rotated successfully."
                      dismissible={true}
                      onDismiss={() => setSuccess(false)}
                    ></Alert>
                  </>
                ) : (
                  <>
                    {error !== null && (
                      <Alert
                        type="danger"
                        message={error}
                        dismissible={true}
                        onDismiss={() => setError(null)}
                      ></Alert>
                    )}
                  </>
                )}
                <div className="list-group">
                  {(!userData.currentAccount.subscriptionStatus ||
                    userData.currentAccount.subscriptionStatus !==
                      "active") && (
                    <div className="row">
                      <div className="col-sm-12 col-md-3 text-center mt-1 mb-4">
                        <Link
                          to={
                            "/account/" +
                            userData.currentAccount.id +
                            "/billing/plan"
                          }
                          className="btn btn-success btn-lg mr-2"
                        >
                          Buy Subscription Plan
                        </Link>
                      </div>
                      <div className="col-sm-12 col-md-9 text-left">
                        <Alert
                          type="warning"
                          message="You're on a Free Trial plan with limited number of requests and 1000 characters per request. To access all of Lecto Translation API's features, Buy a Subscription Plan."
                          dismissible={false}
                        ></Alert>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-12 col-md-4 text-center row-title mt-1">
                      <strong className="h3">API Key</strong>
                      <br></br>
                      <br></br>
                      <strong>
                        Case-sensitive. Value of the `X-API-Key` header.
                      </strong>
                    </div>
                    <div className="h4 col-sm-12 col-md-5 text-center mt-1">
                      {apiKey ? (
                        <CopyBlock
                          text={apiKey}
                          language="text"
                          theme={monokai}
                          showLineNumbers={false}
                          codeBlock
                        />
                      ) : (
                        <div>
                          <Loader />
                          <p>
                            Loading API Key. Just a few more moments longer...
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-3 text-center mt-2">
                      <button
                        className="btn btn-danger mr-2"
                        disabled={inSubmit}
                        onClick={(e) => {
                          e.preventDefault();
                          setApiKeyConfirm(true);
                        }}
                      >
                        {inSubmit && <Loader />}
                        Rotate API Key
                      </button>
                    </div>
                  </div>
                  {apiKeyConfirm && (
                    <div className="row">
                      <div className="col-sm-12 col-md-9 text-right">
                        <p className="text-danger">
                          <strong>
                            Your current API Key will be revoked immediately and
                            replaced by the new API Key. Are you sure?
                          </strong>
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-3 text-center">
                        <button
                          className="btn btn-danger mr-2"
                          disabled={inSubmit}
                          onClick={(e) => {
                            e.preventDefault();
                            setInSubmit(true);
                            const updateAccountAPIKey = CloudFunctions.httpsCallable(
                              "updateAccountAPIKey"
                            );
                            updateAccountAPIKey({
                              accountId: userData.currentAccount.id,
                            })
                              .then((res) => {
                                localStorage.setItem(
                                  apiKeyCacheKey,
                                  res.data.apiKey
                                );
                                renderApiKeyFromLocalStorage();
                                setInSubmit(false);
                                setApiKeyConfirm(false);
                                setSuccess(true);
                              })
                              .catch((err) => {
                                setInSubmit(false);
                                setError(err.message);
                              });
                          }}
                        >
                          {inSubmit && <Loader />}
                          Yes, I'm sure. Rotate API Key.
                        </button>
                        <button
                          className="btn btn-secondary"
                          disabled={inSubmit}
                          onClick={(e) => {
                            e.preventDefault();
                            setApiKeyConfirm(false);
                          }}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  )}
                  {(!userData.currentAccount.subscriptionStatus ||
                    userData.currentAccount.subscriptionStatus !==
                      "active") && (
                    <div className="row">
                      <div className="col-sm-12 col-md-6 text-left">
                        <Alert
                          type="success"
                          message="Free Trial Over? Not to worry! To continue your translations, purchase a Pay-as-you-go or Subscription Plan. Prices begin at $2.99 per million characters or $1.99 per month. Paypal or Debit/Credit Cards accepted."
                          dismissible={false}
                          className="h2"
                        ></Alert>
                      </div>
                      <div className="col-sm-12 col-md-3 text-right mt-1">
                        <Link
                          to={
                            "/account/" +
                            userData.currentAccount.id +
                            "/billing/plan"
                          }
                          className="btn btn-success btn-lg mr-2"
                        >
                          Purchase Now To Continue
                        </Link>
                      </div>
                      <div className="col-sm-12 col-md-3 text-center mt-1">
                        <Link
                          to={{
                            pathname: `https://go.crisp.chat/chat/embed/?website_id=${window.CRISP_WEBSITE_ID}&user_email=${userData.currentAccount.name}`,
                          }}
                          target="_blank"
                          rel="nofollow"
                          className="btn btn-primary btn-lg mr-2"
                        >
                          Need help? Chat with Us
                        </Link>
                      </div>
                    </div>
                  )}
                  {userData.currentAccount.subscriptionStatus &&
                    userData.currentAccount.subscriptionStatus === "active" && (
                      <div className="row">
                        <div className="col-sm-12 col-md-4 text-center">
                          {planState.name ? (
                            <strong>
                              {" "}
                              Subscription Plan<br></br>
                              <br></br> {planState.name} plan (
                              {new Date(
                                userData.currentAccount
                                  .subscriptionCurrentPeriodStart * 1000
                              ).toLocaleDateString()}{" "}
                              to{" "}
                              {new Date(
                                userData.currentAccount
                                  .subscriptionCurrentPeriodEnd * 1000
                              ).toLocaleDateString()}
                              )
                            </strong>
                          ) : (
                            <strong>Loading...</strong>
                          )}
                        </div>
                        <div className="col-sm-1 col-md-1 text-center">
                          {/* Spacer */}
                        </div>
                        <div className="col-sm-12 col-md-3 text-center mt-4 mb-4 mt-md-0 mb-md-0">
                          {planState.usageRetrieved ? (
                            <>
                              <strong>
                                Plan Usage: {planState.usage.toLocaleString()}/
                                {planState.usageLimit.toLocaleString()}{" "}
                                {planState.usageType}s
                              </strong>
                              <ProgressBar
                                width="400px"
                                height="10px"
                                rect
                                fontColor="#3c4b64"
                                percentage={planState.usagePercentage.toString()}
                                rectPadding="1px"
                                rectBorderRadius="10px"
                                trackPathColor="transparent"
                                trackBorderColor="grey"
                                defColor={{
                                  // reverse of default as 100% quota is worse for user
                                  // using monokai colors for poor and excellent
                                  good: "orangered",
                                  fair: "yellow",
                                  poor: "#96c43c",
                                  excellent: "#e83e8c",
                                }}
                              />
                            </>
                          ) : (
                            <strong>Plan Usage: Loading...</strong>
                          )}
                        </div>
                        <div className="col-sm-1 col-md-1 text-center">
                          {/* Spacer */}
                        </div>
                        <div className="col-sm-12 col-md-3 text-center">
                          <Link
                            to={
                              "/account/" +
                              userData.currentAccount.id +
                              "/billing"
                            }
                            className="btn btn-primary mr-2"
                          >
                            Billing Details
                          </Link>
                          <Link
                            to={
                              "/account/" +
                              userData.currentAccount.id +
                              "/billing/plan"
                            }
                            className="btn btn-primary mr-2"
                          >
                            Change Plan
                          </Link>
                        </div>
                      </div>
                    )}

                  <div className="row">
                    <div className="col-sm-12 col-md-6 order-md-1 text-center row-title codeblock">
                      <h4>Sample Text Translation Request</h4>
                    </div>
                    <div className="col-sm-12 col-md-6 order-md-12 text-left codeblock">
                      <CopyBlock
                        text={`curl -X POST "${
                          process.env.REACT_APP_TRANSLATE_API_URL
                        }/v1/translate/text" \\
                      -H 'X-API-Key: ${apiKey || `<API-Key>`}' \\
                      -H 'Content-Type: application/json' \\
                      -H 'Accept: application/json' \\
                      --data-raw '{
                          "texts": ["Just try it mate.","What are you waiting for?"],
                          "to": ["pt-BR", "de"],
                          "from": "en"
                      }' --compressed`}
                        language="bash"
                        theme={monokai}
                        showLineNumbers={false}
                        codeBlock
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 order-md-7 text-center row-title codeblock">
                      <h4>Sample JSON Translation Request</h4>
                    </div>
                    <div className="col-sm-12 col-md-6 order-md-12 text-left codeblock">
                      <CopyBlock
                        text={`curl -X POST "${
                          process.env.REACT_APP_TRANSLATE_API_URL
                        }/v1/translate/json" \\
                      -H 'X-API-Key: ${apiKey || `<API-Key>`}' \\
                      -H 'Content-Type: application/json' \\
                      -H 'Accept: application/json' \\
                      --data-raw '{
                        "to": ["pt-BR", "de"],
                        "from": "en",
                        "protected_keys": ["team.name"],
                        "json": "{\\"team\\":{\\"name\\":\\"Los Angeles Lakers\\",\\"player_summary\\":{\\"LeBron James\\":\\"Widely considered one of the greatest NBA players in history.\\",\\"Andre Drummond\\":\\"A two-time NBA All-Star, he has led the league in rebounding four times and also been named to the All-NBA Third Team.\\"}}}"
                    }' --compressed`}
                        language="bash"
                        theme={monokai}
                        showLineNumbers={false}
                        codeBlock
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 text-center mb-4">
                    <Link
                      to={{
                        pathname:
                          "https://github.com/lecto-ai/docs/tree/main/examples",
                      }}
                      target="_blank"
                      rel="nofollow"
                      className="btn btn-primary mr-2"
                    >
                      PHP/Python/NodeJS Code Examples on Github
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 text-center">
                    <h3>API Documentation</h3>
                  </div>
                </div>
                <RedocStandalone
                  specUrl={swaggerObject ? null : "/swagger.yaml"}
                  spec={swaggerObject || null}
                  options={{
                    nativeScrollbars: true,
                    theme: { colors: { primary: { main: "#597EFC" } } },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {pricingRedirect && (
        <Redirect
          to={{
            ...document.location,
            pathname: document.location.pathname.concat("", "billing/plan"),
          }} // redirect to billing plan page with pricing query, used to deep link from emails
        />
      )}
    </>
  );
};

export default Overview;
