import React from "react";
import { NavLink } from "react-router-dom";

const Logo = () => {
  return (
    <>
      <NavLink className="c-sidebar-brand-full" to="/">
        <h4 className="text-light c-sidebar-brand-full">
          <img
            src="/lectoai_logo.png"
            alt="Lecto AI"
            className="c-sidebar-brand-full"
            height="30%"
            width="30%"
          />
          <p className="default-row-spacer">Lecto AI</p>
        </h4>
      </NavLink>
      <NavLink className="c-sidebar-brand-minimized" to="/">
        <h4 className="c-sidebar-brand-minimized">
          <img
            src="/lectoai_logo.png"
            alt="Lecto AI"
            className="c-sidebar-brand-minimized"
            height="100%"
            width="100%"
          />
        </h4>
      </NavLink>
    </>
  );
};

export default Logo;
