import React, { useState, useContext, useEffect, useRef } from "react";
import { BreadcrumbContext } from "../../../components/Breadcrumb";
import {
  FirebaseAuth,
  CloudFunctions,
} from "../../../components/FirebaseAuth/firebase";
import { Redirect, useLocation } from "react-router-dom";
import Loader from "../../../components/Loader";
import Alert from "../../../components/Alert";
import { SidebarContext } from "../../../components/templates/PublicTemplate";
import { getFingerprint, getIP, userSignOut } from "../../../libs/user";

const Home = () => {
  const title = "Home";

  // sidebar context to disable when loading
  const { setSidebar } = useContext(SidebarContext);
  // read dynamic query params
  const search = useLocation().search;
  const pricingQuery = new URLSearchParams(search).get("pricing");

  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [errorMessage, setErrorMessage] = useState(null);

  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [pricingRedirect, setPricingRedirect] = useState(false);

  const mountedRef = useRef(true);

  useEffect(() => {
    const checkPricingRedirect = () => {
      if (pricingQuery === "true") {
        setPricingRedirect(true);
      }
    };
    checkPricingRedirect();

    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);

    const getAccounts = async () => {
      setSidebar("disabled"); // disable sidebar while we create account etc. to avoid users trying to create API Key

      setLoading(true);

      // if not signed in, redirect to docs page
      if (!FirebaseAuth.auth().currentUser) {
        setSidebar("enabled");
        setLoading(false);
        return null;
      }

      let records = [];
      const accountsRef = FirebaseAuth.firestore().collection("accounts");
      let query = accountsRef.where(
        "access",
        "array-contains",
        FirebaseAuth.auth().currentUser.uid
      );
      return query.get().then((accountSnapshots) => {
        if (!mountedRef.current) return null;
        accountSnapshots.forEach((account) => {
          records.push({
            id: account.id,
            name: account.data().name,
            subscriptionStatus: account.data().subscriptionStatus,
          });
        });

        if (records.length === 0) {
          Promise.all([getFingerprint(), getIP()])
            .then(([fingerprint, ip]) => {
              // if no accounts, create an account with account name = user uid
              // reduces Time to 200
              const createAccount = CloudFunctions.httpsCallable(
                "createAccount"
              );
              return createAccount({
                accountName: FirebaseAuth.auth().currentUser.email,
                fp: fingerprint,
                ip: ip,
              });
            })
            .then((response) => {
              query.get().then((accountSnapshots) => {
                if (!mountedRef.current) return null;
                accountSnapshots.forEach((account) => {
                  records.push({
                    id: account.id,
                    name: account.data().name,
                    subscriptionStatus: account.data().subscriptionStatus,
                  });
                });
                setAccounts(records);
                setSidebar("enabled");
                setLoading(false);
              });
            })
            .catch((err) => {
              setErrorMessage(err.message);
              setSidebar("enabled");
              setLoading(false);
              userSignOut(); // sign out user to prevent redirect loops with errors
            });
        } else {
          setAccounts(records);
          setSidebar("enabled");
          setLoading(false);
        }
      });
    };

    if (accounts.length === 0) {
      getAccounts();
    }

    return () => {
      mountedRef.current = false;
    };
  }, [setBreadcrumb, pricingQuery, setSidebar, accounts.length]);

  return (
    <>
      <div className="container-fluid">
        <div className="animated fadeIn">
          {errorMessage && (
            <Alert
              type="danger"
              message={errorMessage}
              dismissible={false} // nothing other than error message here
              onDismiss={() => setErrorMessage(null)}
            ></Alert>
          )}
          {accounts.length > 0 ? (
            <>
              {accounts.map((account, i) => (
                <div className="card" key={account.id}>
                  <div className="card-header font-weight-bold">
                    <h5>{account.name}</h5>
                  </div>
                  {pricingRedirect ? (
                    <div className="card-body">
                      <Redirect
                        to={"/account/" + account.id + "/?pricing=true"}
                      ></Redirect>
                    </div>
                  ) : (
                    <div className="card-body">
                      <Redirect to={"/account/" + account.id + "/"}></Redirect>
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              {loading ? (
                <Loader text="Loading your account... Your API Key is being generated by our 🤖, please wait."></Loader>
              ) : (
                !errorMessage && (
                  <Redirect
                    to={{
                      ...document.location,
                      pathname: document.location.pathname.replace(
                        "/",
                        "/docs"
                      ),
                    }}
                  />
                )
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
