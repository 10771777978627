import React, { useState, useContext, useEffect, useRef } from "react";
import { FirebaseAuth } from "../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from "../../../components/Breadcrumb";
import Loader from "../../../components/Loader";
import Price from "../../../components/Price";
import { Redirect } from "react-router-dom";
import firebase from "firebase/app";

const Pricing = () => {
  const title = "Lecto Translation API Pricing";

  const mountedRef = useRef(true);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [signedOut, setSignedOut] = useState(true);

  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [browserLanguage, setBrowserLanguage] = useState("en-US"); // use for price display
  const [creditsPrice, setCreditsPrice] = useState(0); // use for credits pricing plan only
  const [creditsQuantity, setCreditsQuantity] = useState(0); // use for credits pricing plan only

  // formatPrice formats a given price as per user's browser language
  const formatPrice = (price, decimalPlaces) => {
    const finalisedDecimalPlaces = decimalPlaces || (price > 10 ? 0 : 2);

    return new Intl.NumberFormat(browserLanguage, {
      minimumFractionDigits: finalisedDecimalPlaces,
      maximumFractionDigits: finalisedDecimalPlaces,
    }).format(price);
  };
  const onCreditsPriceChange = (creditsPrice, creditsQuantity) => {
    setCreditsPrice(creditsPrice);
    setCreditsQuantity(creditsQuantity);
  };

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
    setLoading(true);

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // redirect to home page for logged in user
        // which will then again redirect to the account biling plan page
        setSignedOut(false);
      }
    });

    const getLang = () =>
      navigator.language ||
      navigator.browserLanguage ||
      (navigator.languages || ["en-US"])[0];
    setBrowserLanguage(getLang());

    const plansQuery = FirebaseAuth.firestore()
      .collection("plans")
      .orderBy("price", "asc");
    plansQuery.get().then((planSnapShots) => {
      if (!mountedRef.current) return null;
      let p = [];
      planSnapShots.forEach((doc) => {
        const plan = {
          id: doc.id,
          name: doc.data().name,
          price: doc.data().price,
          quantities: doc.data().quantities, // for credits based plans only, null otherwise
          isMeteredSubscription: doc.data().isMeteredSubscription,
          usageLimit: doc.data().usageLimit,
          usageType: doc.data().usageType,
          overage: doc.data().overage,
          currency: doc.data().currency,
          paymentCycle: doc.data().paymentCycle,
          features: doc.data().features,
          deprecated: doc.data().deprecated,
          custom: doc.data().custom,
        };
        if (!plan.deprecated && !plan.custom) {
          // remove deprecated and user specific custom plans
          p.push(plan);
        }
      });
      if (p.length > 0) {
        const ascendingOrderPlans = p.sort(
          (a, b) => parseFloat(a.price) - parseFloat(b.price)
        );
        // order pay as you go plans before subscriptipn plans
        const ascendingNonMeteredPlans = ascendingOrderPlans.sort((a, b) => {
          if (a.isMeteredSubscription === b.isMeteredSubscription) return 0;
          if (a.isMeteredSubscription) return 1;
          return -1;
        });

        setPlans(ascendingNonMeteredPlans);
      }
      setLoading(false);
    });
    return () => {
      mountedRef.current = false;
    };
  }, [setBreadcrumb, title]);

  return (
    <>
      {signedOut ? (
        <div className="container-fluid">
          <div className="animated fadeIn">
            <div className="card-deck mb-3">
              <div className="card">
                <div className="card-header text-center">
                  <h1 className="h2 text-responsive-large">{title}</h1>
                  <h2 className="h4 text-responsive-small">
                    Translation API plans built to meet your bulk translation
                    workflows. Pick one based on your translation requirements
                    and get started right now. Or get in touch with us for
                    custom features or to license our translation software for
                    on-premise use.
                  </h2>
                  <ul className="row col-sm-12 d-flex justify-content-center">
                    <li key="paypal">
                      <i className="c-sidebar-nav-icon fa fa-credit-card"></i>{" "}
                      Paypal, Debit/Credit Cards supported. Payments handled
                      securely by Stripe.
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <>
                    {plans.length > 0 ? (
                      <div className="row justify-content-md-center">
                        <div className="col col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="card-deck mb-5 text-center">
                            {plans.map((plan) => (
                              <div className="card" key={plan.id}>
                                <Price
                                  key={plan.id}
                                  plan={plan}
                                  creditsPrice={creditsPrice}
                                  creditsQuantity={creditsQuantity}
                                  formatPrice={formatPrice}
                                  onCreditsPriceChange={onCreditsPriceChange}
                                />
                                <div className="card-footer bg-white">
                                  <a
                                    href="/signin"
                                    className="btn btn-block btn-outline-success"
                                    rel="noopener noreferrer"
                                  >
                                    {plan.isMeteredSubscription
                                      ? "Subscribe now"
                                      : "Buy now"}
                                  </a>
                                </div>
                              </div>
                            ))}
                            <div className="card" key="enterprise-card">
                              <div className="card-header">
                                <h2 className="my-0 font-weight-normal card-title">
                                  Enterprise
                                </h2>
                                <br />
                                <h5 className="card-subtitle text-muted">
                                  Design a custom plan for your business or
                                  license our API for on-premise deployment
                                </h5>
                              </div>
                              <div className="card-body">
                                <ul className="list-unstyled mt-3 mb-4 text-left">
                                  <br></br>
                                  <li key="enterprise-unlimited">
                                    <i className="fa fa-check-circle text-success"></i>{" "}
                                    Unlimited translated characters and parallel
                                    requests
                                  </li>
                                  <br></br>
                                  <li key="enterprise-support">
                                    <i className="fa fa-check-circle text-success"></i>{" "}
                                    Dedicated customer support
                                  </li>
                                  <br></br>
                                  <li key="enterprise-discount">
                                    <i className="fa fa-check-circle text-success"></i>{" "}
                                    Volume discounts
                                  </li>
                                  <br></br>
                                  <li key="enterprise-payment">
                                    <i className="fa fa-check-circle text-success"></i>{" "}
                                    Custom payment methods such as Bank
                                    Transfers
                                  </li>
                                  <br></br>
                                  <li key="enterprise-licensing">
                                    <i className="fa fa-check-circle text-success"></i>{" "}
                                    License for deployment on-premise or in your
                                    own Cloud environment
                                  </li>
                                </ul>
                              </div>
                              <div className="card-footer bg-white">
                                <a
                                  href="mailto:%73%75%70%70%6f%72%74%40%6c%65%63%74%6f%2e%61%69"
                                  className="btn btn-block btn-outline-success"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Contact Us
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {loading ? (
                          <Loader text="loading plans..."></Loader>
                        ) : (
                          <div>No plan is found</div>
                        )}
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Redirect to="/?pricing=true"></Redirect>
        </>
      )}
    </>
  );
};
export default Pricing;
