import React, { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    var s = document.createElement("script");
    let tag = document.getElementsByTagName("script")[0];

    s.src = "https://cdn.iubenda.com/iubenda.js";

    tag.parentNode.insertBefore(s, tag);
  }, []);

  const policyId = 76979970; // lecto.ai privacy policy https://www.iubenda.com/privacy-policy/76979970

  return (
    <>
      <div>
        <a
          href={`https://www.iubenda.com/privacy-policy/${policyId}`}
          class="iubenda-white iubenda-embed iubenda-noiframe"
          title="Privacy Policy "
        >
          Privacy Policy
        </a>
      </div>

      <div>
        <i className="fa fa-5x text-warning"></i>
        <div class="container">
          <h1 class="text-center mt-100 mb-100">Terms Of Use</h1>
          <h2>The Gist</h2>
          <p>
            Lecto AI Limited operates the Lecto Translation API service (the
            “Service”), which we hope You (the “Customer”, “You”, or “Your”)
            use. If You use it, please use it responsibly.
          </p>
          <p>
            When You use the Service (as defined herein), you agree to be bound
            by the Terms (whether or not You have created an account) and Lecto
            Translation API will treat Your use of the Service as acceptance of
            the Terms.
          </p>
          <p>
            If You don’t use the Service responsibly or in accordance with these
            Terms of Use, we will terminate Your account.
          </p>
          <p>
            You own the business data that you provide to Lecto Translation API
            and You are solely responsible for keeping it safe.
          </p>
          <p>
            These Terms of Use (the “Terms”), the Service, and our prices can
            change at any time.
          </p>
          <p>
            If you have any question about the Terms, please contact us at{" "}
            <a
              class="n01"
              href="mailto:%73%75%70%70%6f%72%74%40%6c%65%63%74%6f%2e%61%69"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@lecto.ai
            </a>
          </p>
          <h2>About Us</h2>
          <p>
            The Service is provided and operated by Lecto AI Limited. We are a
            Limited Liability Company registered in Hong Kong SAR with
            registered number 3090750 and have our registered office in:
          </p>
          <p>
            Unit 2302, 23/F, New World Tower 1,
            <br />
            18 Queen’s Road Central, Central, Hong Kong.
          </p>
          <h2>Your Agreement with Lecto Translation API</h2>
          <p>
            Your use of the Service is governed by these Terms. The “Service”
            means the services (including all features and functionalities)
            Lecto Translation API makes available on our web sites
            (https://lecto.ai/), our blog, our API, and any other software,
            sites, and services offered by Lecto Translation API under or in
            connection to any of those.
          </p>
          <p>
            Lecto Translation API may make changes to the Terms from time to
            time. You may reject the changes by terminating Your account. You
            understand and agree that if You use the Service after the date on
            which the Terms have changed, Lecto Translation API will treat Your
            use as acceptance of the updated Terms.
          </p>
          <h2>Your Account</h2>
          <p>
            You may not use the Service if You are a person barred from
            receiving the Service under the laws of Hong Kong SAR or any other
            countries, including the country in which You are resident or from
            which You use the Service.
          </p>
          <p>
            You must be a natural person over the age of 13. Accounts created by
            automated methods are not permitted.
          </p>
          <h2>Use of the Service</h2>
          <p>
            You must provide accurate and complete registration information any
            time You register to use the Service.
          </p>
          <p>
            You are responsible for the security of Your passwords and for any
            use of Your account.
          </p>
          <p>
            Your use of the Service must comply with all applicable laws,
            regulations and ordinances of Hong Kong SAR, the country in which
            you reside, and the country from which you are using the Service.
          </p>
          <p>
            You agree to not engage in any activity that interferes with or
            disrupts the Service.
          </p>
          <p>
            You may not allow multiple people to use the same account or
            otherwise access the Service in a manner intended to avoid incurring
            fees.
          </p>
          <p>
            Lecto Translation API reserves the right to enforce quotas and usage
            limits (to any resources, including the API) at its sole discretion,
            with or without notice, which may result in Lecto Translation API
            disabling or throttling Your usage of the Service for any amount of
            time.
          </p>
          <h2>Privacy Policy</h2>
          <p>
            The Service shall be subject to the privacy policy for the Service
            available on the{" "}
            <a
              href={`https://www.iubenda.com/privacy-policy/${policyId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy page
            </a>{" "}
            (the “Privacy Policy”), which are hereby expressly incorporated into
            these Terms by reference. You agree to the use of Your data in
            accordance with Lecto Translation API’s Privacy Policy.
          </p>
          <h2>Service Fees</h2>
          <p>
            The Service may be provided to You without charge up with certain
            limits (the “Limit”) or for a certain period of time (the “Trial
            Period”). Usage of the Service over the Limit or after the Trial
            Period will be charged a fee (the “Fee”).
          </p>
          <p>
            The Fee will depend on the subscription plan you select (the
            “Plan”). Plans may have differing conditions and limitations, which
            will be disclosed at your sign-up or in other communications made
            available to you.
          </p>
          <p>
            All Plans are on a rolling 30 day-basis and we will charge Your
            credit card the Fee every 30 days starting from the day you sign-up
            for the Plan. You acknowledge and agree that any credit card and
            related billing and payment information that You provide to Lecto
            Translation API may be shared by Lecto Translation API with
            companies who work on Lecto Translation API’s behalf, such as
            payment processors and/or credit agencies, solely for the purposes
            of checking credit, effecting payment to Lecto Translation API and
            servicing Your account.
          </p>
          <p>
            All Fees are non-refundable. There will be no refunds or credits for
            partial months of service, upgrade/downgrade refunds, or refunds for
            months unused with an open account.
          </p>
          <p>
            Charges are solely based on Lecto Translation API’s measurements of
            Your use of the Service, unless otherwise agreed to in writing.
          </p>
          <p>
            All Fees are exclusive of all taxes, levies, or duties imposed by
            taxing authorities, and You shall be responsible for payment of all
            such taxes, levies, or duties.
          </p>
          <p>
            Lecto Translation API may change its fees and payment policies for
            the Service by notifying You at least 30 days before the beginning
            of the billing cycle in which such change will take effect.
          </p>
          <h2>Cancellation and Termination</h2>
          <p>
            When You cancel Your Plan, Your cancellation will take effect
            immediately and no subsequent charges will be made to your credit
            card, unless you agree to a Plan again. You will not receive any
            refund(s) when you cancel Your Plan.
          </p>
          <p>
            To cancel Your account, you must send an email request to{" "}
            <a
              class="n01"
              href="mailto:%73%75%70%70%6f%72%74%40%6c%65%63%74%6f%2e%61%69"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@lecto.ai
            </a>{" "}
            using the email registered to your account. Any other emails or
            phone requests to cancel Your account will not be considered a
            cancellation.
          </p>
          <p>
            You agree that Lecto Translation API, in its sole discretion and for
            any or no reason, may suspend or terminate Your Plan and/or account.
            You agree that any such suspension or termination may be without
            prior notice, and You agree that Lecto Translation API will not be
            liable to You or any third party for such suspension or termination.
          </p>
          <h2>Ideas and Feedback</h2>
          <p>
            You may choose to or we may invite You to submit comments or ideas
            about the Service, including but not limited to ideas about
            improving the Service or our products (“Ideas”). By submitting any
            Idea, You agree that Your disclosure is unsolicited and without
            restriction and will not place Lecto Translation API under any
            fiduciary or other obligation, and that we are free to use the Idea
            without any additional compensation to You, and/or to disclose the
            Idea on a non-confidential basis or otherwise to anyone.
          </p>
          <h2>Modification of the Service</h2>
          <p>
            You acknowledge and agree that the Service may change from time to
            time without prior notice to You. Changes include, without
            limitation, changes to fee and payment policies, security patches,
            added or removed functionality, and other enhancements or
            restrictions.
          </p>
          <p>
            Lecto Translation API shall not be liable to You or to any third
            party for any modification, price change, suspension or
            discontinuance of the Service.
          </p>
          <h2>External Resources</h2>
          <p>
            The Service may include hyperlinks to other web sites or content or
            resources or email content. You acknowledge and agree that Lecto
            Translation API is not responsible for the availability of any such
            external sites or resources, and does not endorse any advertising,
            products or other materials on or available from such web sites or
            resources.
          </p>
          <h2>License from Lecto Translation API and Restrictions</h2>
          <p>
            All of the content available on or through the Service, including
            without limitation, text, photographs, graphics, logos,
            trade/service marks, and/or audiovisual content, is owned and/or
            controlled by Lecto Translation API, or other licensors or Service
            users and is protected, as applicable, by copyright, trademark,
            trade dress, patent, and trade secret laws, other proprietary
            rights, and international treaties. You acknowledge that the Service
            and any underlying technology or software used in connection with
            the Service contain our proprietary information.
          </p>
          <p>
            Subject to and conditioned upon Your compliance with these Terms, we
            grant to you a personal, worldwide, royalty-free, non-assignable and
            non-exclusive license to use the software provided to You by Lecto
            Translation API as part of the Service as provided to You by Lecto
            Translation API. This license is for the sole purpose of enabling
            You to use and enjoy the benefit of the Service as provided by Lecto
            Translation API, in the manner permitted by the Terms.
          </p>
          <p>
            You may not (and You may not permit anyone else to): (a) copy,
            modify, create a derivative work of, reverse engineer, decompile or
            otherwise attempt to extract the source code of the Service or any
            part thereof, unless this is expressly permitted or required by law,
            or unless You have been specifically told that You may do so by
            Lecto Translation API, in writing (e.g., through an open source
            software license); or (b) attempt to disable or circumvent any
            security mechanisms used by the Service.
          </p>
          <p>
            Open source software licenses for components of the Service released
            under an open source license constitute separate written agreements.
            To the limited extent that the open source software licenses
            expressly supersede these Terms, the open source licenses govern
            Your agreement with Lecto Translation API for the use of the
            components of the Service released under an open source license.
          </p>
          <p>
            You may not use the Service in any manner that could damage,
            disable, overburden or impair our servers or networks, or interfere
            with any other users’ use or enjoyment of the Service.
          </p>
          <p>
            You may not attempt to gain unauthorized access to any of the
            Service, member accounts, or computer systems or networks, through
            hacking, password mining or any other means.
          </p>
          <p>
            Without limiting anything else contained herein, You agree that You
            shall not (and You agree not to allow any third party to):
          </p>
          <ul>
            <li>
              <p>
                remove any notices of copyright, trademark or other proprietary
                rights contained in/on or accessible through the Service or in
                any content or other material obtained via the Service;
              </p>
            </li>
            <li>
              <p>
                use any robot, spider, website search/retrieval application, or
                other automated device, process or means to access, retrieve or
                index any portion of the Service;
              </p>
            </li>
            <li>
              <p>
                reformat or frame any portion of the web pages that are part of
                the Service;
              </p>
            </li>
            <li>
              <p>
                use the Service for commercial purposes not permitted under
                these Terms;
              </p>
            </li>
            <li>
              <p>
                create user accounts by automated means or under false or
                fraudulent pretenses;
              </p>
            </li>
            <li>
              <p>
                attempt to defeat any security or verification measure relating
                to the Service;
              </p>
            </li>
            <li>
              <p>
                provide or use tracking or monitoring functionality in
                connection with the Service, including, without limitation, to
                identify other users’ actions or activities;
              </p>
            </li>
            <li>
              <p>
                impersonate or attempt to impersonate Lecto Translation API or
                any employee, contractor or associate of Lecto Translation API,
                or any other person or entity; or
              </p>
            </li>
            <li>
              <p>
                collect or store personal data about other users in connection
                with the prohibited activities described in this paragraph.
              </p>
            </li>
          </ul>
          <h2>Our Copyright Dispute Policy</h2>
          <p>
            Lecto Translation API respects the intellectual property of others
            and requires that our users do the same. It is our policy to
            terminate the membership of repeat infringers. If You believe that
            material or content residing on or accessible through the Service
            infringes a copyright, please send a notice of copyright
            infringement containing the following information to{" "}
            <a
              class="n01"
              href="mailto:%73%75%70%70%6f%72%74%40%6c%65%63%74%6f%2e%61%69"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@lecto.ai
            </a>
            :
          </p>
          <ul>
            <li>
              <p>
                identification of the copyrighted work claimed to have been
                infringed, or, if multiple copyrighted works are covered by a
                single notification, a representative list of such works;
              </p>
            </li>
            <li>
              <p>
                identification of the claimed infringing material and
                information reasonably sufficient to permit us to locate the
                material on the Lecto Translation API Service (providing the
                URL(s) of the claimed infringing material satisfies this
                requirement);
              </p>
            </li>
            <li>
              <p>
                information reasonably sufficient to permit us to contact You,
                such as an address, telephone number, and an email address;
              </p>
            </li>
            <li>
              <p>
                a statement by You that You have a good faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law;
              </p>
            </li>
            <li>
              <p>
                a statement by You, made under penalty of perjury, that the
                above information in Your notification is accurate and that You
                are the copyright owner or are authorized to act on the
                copyright owner’s behalf; and
              </p>
            </li>
            <li>
              <p>your physical or electronic signature.</p>
            </li>
          </ul>
          <h2>Links to Other Websites</h2>
          <p>
            The Service may contain advertisements and/or links to other
            websites (“Third Party Sites”). Lecto Translation API does not
            endorse, sanction or verify the accuracy or ownership of the
            information contained in/on any Third Party Site or any products or
            services advertised on Third Party Sites. If You decide to leave the
            Site and navigate to Third Party Sites, or install any software or
            download content from any such Third Party Sites, You do so at Your
            own risk. Once You access a Third Party Site through a link on our
            Site, You may no longer be protected by these Terms of Service and
            You may be subject to the terms and conditions of such Third Party
            Site. You should review the applicable policies, including privacy
            and data gathering practices, of any Third Party Site to which You
            navigate from the Site, or relating to any software You use or
            install from a Third Party Site. Concerns regarding a Third Party
            Site should be directed to the Third Party Site itself. Lecto
            Translation API bears no responsibility for any action associated
            with any Third Party Site.
          </p>
          <h2>Disclaimer of Warranties</h2>
          <p>
            IF YOU ACCESS THE SERVICE, YOU DO SO AT YOUR OWN RISK. WE PROVIDE
            THE SERVICE “AS IS”, “WITH ALL FAULTS” AND “AS AVAILABLE.” WE MAKE
            NO EXPRESS OR IMPLIED WARRANTIES OR GUARANTEES ABOUT THE SERVICE. TO
            THE MAXIMUM EXTENT PERMITTED BY LAW, WE HEREBY DISCLAIM ALL SUCH
            WARRANTIES, INCLUDING ALL STATUTORY WARRANTIES, WITH RESPECT TO THE
            SERVICE, INCLUDING WITHOUT LIMITATION ANY WARRANTIES THAT THE
            SERVICE IS MERCHANTABLE, OF SATISFACTORY QUALITY, ACCURATE, FIT FOR
            A PARTICULAR PURPOSE OR NEED, OR NON-INFRINGING. WE DO NOT GUARANTEE
            THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE
            WILL BE EFFECTIVE, RELIABLE OR ACCURATE OR WILL MEET YOUR
            REQUIREMENTS. WE DO NOT GUARANTEE THAT YOU WILL BE ABLE TO ACCESS OR
            USE THE SERVICE (EITHER DIRECTLY OR THROUGH THIRD-PARTY NETWORKS) AT
            TIMES OR LOCATIONS OF YOUR CHOOSING. WE ARE NOT RESPONSIBLE FOR THE
            ACCURACY, RELIABILITY, TIMELINESS OR COMPLETENESS OF INFORMATION
            PROVIDED BY ANY OTHER USERS OF THE SERVICE OR ANY OTHER DATA OR
            INFORMATION PROVIDED OR RECEIVED THROUGH THE SERVICE. EXCEPT AS
            EXPRESSLY SET FORTH HEREIN, LECTO TRANSLATION API MAKES NO
            WARRANTIES ABOUT THE INFORMATION SYSTEMS, SOFTWARE AND FUNCTIONS
            MADE ACCESSIBLE BY OR THROUGH THE SERVICE OR ANY SECURITY ASSOCIATED
            WITH THE TRANSMISSION OF SENSITIVE INFORMATION. Lecto Translation
            API DOES NOT WARRANT THAT THE SERVICE WILL OPERATE ERROR-FREE, THAT
            ERRORS IN THE SERVICE WILL BE FIXED, THAT LOSS OF DATA WILL NOT
            OCCUR, OR THAT THE SERVICE OR SOFTWARE ARE FREE OF COMPUTER VIRUSES,
            CONTAMINANTS OR OTHER HARMFUL ITEMS. UNDER NO CIRCUMSTANCES WILL
            LECTO TRANSLATION API, ANY OF OUR AFFILIATES, DISTRIBUTORS,
            PARTNERS, LICENSORS, AND/OR ANY OF OUR OR THEIR DIRECTORS, OFFICERS,
            EMPLOYEES, CONSULTANTS, AGENTS, OR OTHER REPRESENTATIVES BE LIABLE
            FOR ANY LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON INFORMATION
            OBTAINED THROUGH THE SERVICE.
          </p>
          <h2>Limitations on Liability</h2>
          <p>
            NOTWITHSTANDING ANY OTHER PROVISION OF THE TERMS, IN NO EVENT SHALL
            OUR TOTAL CUMULATIVE LIABILITY TO YOU FOR ANY AND ALL CLAIMS
            RELATING TO OR ARISING OUT OF YOUR USE OF THE SERVICE, REGARDLESS OF
            THE FORM OF ACTION, EXCEED THE GREATER OF: (A) THE TOTAL AMOUNT OF
            FEES, IF ANY OR (B) ONE HUNDRED HONG KONG DOLLARS (HK$100).
          </p>
          <p>
            IN NO EVENT SHALL WE BE LIABLE TO YOU (OR TO ANY THIRD PARTY
            CLAIMING UNDER OR THROUGH YOU) FOR ANY DIRECT, INDIRECT, SPECIAL,
            INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES OR ANY
            BODILY INJURY, EMOTIONAL DISTRESS, DEATH OR ANY OTHER DAMAGES
            ARISING FROM YOUR USE OF OR INABILITY TO USE THE SERVICE, WHETHER
            ON-LINE OR OFF-LINE, OR OTHERWISE IN CONNECTION WITH THE SERVICE
            (INCLUDING ANY CLAIMS FOR LOST PROFITS, LOST DATA, LOSS OF GOODWILL
            OR BUSINESS REPUTATION, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR
            SERVICES, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, ANY OTHER
            COMMERCIAL DAMAGES OR LOSSES, OR ANY PERSONAL INJURY OR PROPERTY
            DAMAGES, EVEN IF WE KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF
            SUCH DAMAGES).
          </p>
          <p>
            YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH US IS THE
            CANCELLATION OF YOUR PLAN AND TERMINATION OF YOUR ACCOUNT.
          </p>
          <p>
            IN COUNTRIES WHERE THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR
            CONSEQUENTIAL OR INCIDENTAL DAMAGES IS NOT PERMITTED,, OUR LIABILITY
            SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW. IF YOU ARE A
            CALIFORNIA RESIDENT, YOU WAIVE YOUR RIGHTS WITH RESPECT TO
            CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS “A GENERAL RELEASE
            DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR
            SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE,
            WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT
            WITH THE DEBTOR.”
          </p>
          <h2>Indemnification</h2>
          <p>
            You agree to hold harmless and indemnify Lecto Translation API, and
            its subsidiaries, affiliates, officers, agents, employees,
            advertisers, licensors, suppliers or partners from and against any
            third party claim arising from or in any way related to (a) Your
            breach of the Terms, (b) Your use of the Service, or (c) Your
            violation of applicable laws, rules or regulations in connection
            with the Service, including any liability or expense arising from
            all claims, losses, damages (actual and consequential), suits,
            judgments, litigation costs and attorneys’ fees, of every kind and
            nature. In such a case, Lecto Translation API will provide You with
            written notice of such claim, suit or action.
          </p>
          <h2>Choice of Law and Dispute Resolution</h2>
          <p>
            These Terms of Service shall be governed by the laws of the Hong
            Kong Special Administrative Region and You agree to submit to the
            exclusive jurisdiction of the Hong Kong courts. The sole remedy for
            any and all disputes shall be the cancellation of Your Plan and
            termination of Your account, without resort to any form of class
            action.
          </p>
          <h2>General Legal Terms</h2>
          <p>
            The Terms, including the Privacy Policy, constitute the whole legal
            agreement between You and Lecto Translation API and govern Your use
            of the Service and completely replace any prior agreements between
            You and Lecto Translation API in relation to the Service.
          </p>
          <p>
            If any part of the Terms is held invalid or unenforceable, that
            portion shall be construed in a manner consistent with applicable
            law to reflect, as nearly as possible, the original intentions of
            the parties, and the remaining portions shall remain in full force
            and effect.
          </p>
          <p>
            The failure of Lecto Translation API to exercise or enforce any
            right or provision of the Terms shall not constitute a waiver of
            such right or provision. The failure of either party to exercise in
            any respect any right provided for herein shall not be deemed a
            waiver of any further rights hereunder.
          </p>
          <p>
            You agree that if Lecto Translation API does not exercise or enforce
            any legal right or remedy which is contained in the Terms (or which
            Lecto Translation API has the benefit of under any applicable law),
            this will not be taken to be a formal waiver of Lecto Translation
            API’s rights and that those rights or remedies will still be
            available to Lecto Translation API.
          </p>
          <p>
            Lecto Translation API shall not be liable for failing or delaying
            performance of its obligations resulting from any condition beyond
            its reasonable control, including but not limited to, governmental
            action, acts of terrorism, earthquake, fire, flood or other acts of
            God, labor conditions, power failures, and Internet disturbances.
          </p>
          <p>
            We may assign this contract at any time to any parent, subsidiary,
            or any affiliated company, or as part of the sale to, merger with,
            or other transfer of our company to another entity.
          </p>
          <p>
            Our order process is conducted by our payment processor Stripe.com.
            Stripe.com is the Merchant of Record for all our orders.
          </p>
          <p>Latest update: October 29, 2021</p>
        </div>
      </div>
    </>
  );
};

export default Terms;
