import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/analytics";
import { config } from "./firebase-config";

const FirebaseAuth = firebase.initializeApp(config);
const Firestore = FirebaseAuth.firestore();
const CloudFunctions = FirebaseAuth.functions();
const FirebaseAnalytics = FirebaseAuth.analytics();

// Initialise firebase emulators with `firebase init emulators`
// Run Firebase emulators with firebase emulators:start
if (window.location.hostname === "localhost") {
  FirebaseAuth.auth().useEmulator("http://localhost:9099");
  FirebaseAuth.firestore().useEmulator("localhost", 8080);
  FirebaseAuth.functions().useEmulator("localhost", 5001);
}

export { FirebaseAuth, Firestore, CloudFunctions, FirebaseAnalytics };
