import React, { useEffect } from "react";

// Price component renders a price card based on given plan
// Re-usable on Plans and Public Pricing pages
const Price = ({
  plan,
  creditsPrice,
  creditsQuantity,
  formatPrice,
  onCreditsPriceChange,
  clickBuyCreditsBtn,
}) => {
  useEffect(() => {
    // set default credits price to state
    if (!plan.quantities) {
      return;
    }

    plan.quantities.forEach((quantity) => {
      if (!quantity.default) {
        return;
      }

      onCreditsPriceChange(
        quantity.totalPrice,
        quantity.forStripe,
        quantity.forDisplay
      );
      return;
    });
  }, [plan.quantities]);

  return (
    <>
      <div className="card-header">
        <h2 className="h3 my-0 font-weight-normal card-title">{plan.name}</h2>
        <br />
        <h3 className="card-subtitle">
          $
          {formatPrice(
            !plan.isMeteredSubscription && creditsPrice > 0
              ? creditsPrice / creditsQuantity
              : plan.price
          )}
          <small className="text-muted">/{plan.paymentCycle}</small>
          {plan.overage && (
            <small className="text-muted">
              {" "}
              +{<br></br>} ${formatPrice(plan.overage, 5)} per extra{" "}
              {plan.usageType}{" "}
            </small>
          )}
        </h3>
      </div>
      <div className="card-body">
        {!plan.isMeteredSubscription &&
          plan.quantities &&
          plan.quantities.map((quantity, i) => (
            <>
              <div
                className="form-check text-left mt-2 mb-2"
                key={quantity.totalPrice.toString()}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id={`flexRadioDefault-${i}`}
                  defaultChecked={quantity.default || undefined}
                  onChange={() => {
                    onCreditsPriceChange(
                      quantity.totalPrice,
                      quantity.forStripe,
                      quantity.forDisplay
                    );
                    if (clickBuyCreditsBtn) {
                      clickBuyCreditsBtn(); // immediately click buy credits button if available on pricing plan page
                    }
                  }}
                ></input>
                <label
                  className="form-check-label"
                  htmlFor={`flexRadioDefault-${i}`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  ${formatPrice(quantity.totalPrice, 2)} - {quantity.forDisplay}
                </label>
              </div>
            </>
          ))}
        <ul className="list-unstyled mt-3 mb-4 text-left">
          {plan.features.map((feature) => (
            <>
              <br></br>
              <li key={feature}>
                <i className="fa fa-check-circle text-success"></i> {feature}
              </li>
            </>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Price;
