import React, { useEffect } from "react";
import SidebarLink from "../SidebarLink";

const AppMenu = ({ linksDisabled }) => {
  useEffect(() => {
    document.querySelectorAll(".c-sidebar").forEach((element) => {
      window.coreui.Sidebar._sidebarInterface(element);
    });
  });

  return (
    <ul className="c-sidebar-nav ps ps--active-y">
      <li key="sidebar-referral-title" className="c-sidebar-nav-title">
        Referral Program
      </li>
      <li key="sidebar-referral-link" className="c-sidebar-nav-item">
        <SidebarLink
          className={
            "c-sidebar-nav-link" +
            (window.location.pathname.startsWith("/referral") ? " active" : "")
          }
          to={
            linksDisabled
              ? "#"
              : {
                  pathname: "https://lecto.firstpromoter.com/",
                }
          }
          target={linksDisabled ? null : "_blank"}
          rel="nofollow"
        >
          <i className="c-sidebar-nav-icon fa fa-handshake"></i> Referral
          Program
        </SidebarLink>
      </li>
      <li key="sidebar-account-title" className="c-sidebar-nav-title">
        Account
      </li>
      <li key="sidebar-account-link" className="c-sidebar-nav-item">
        <SidebarLink
          className={
            "c-sidebar-nav-link" +
            (window.location.pathname === "/" ? " active" : "")
          }
          to={linksDisabled ? "#" : "/"}
        >
          <i className="c-sidebar-nav-icon fa fa-book"></i>API Key & Docs
        </SidebarLink>
      </li>
      <li key="sidebar-pricing-title" className="c-sidebar-nav-title">
        Pricing
      </li>
      <li key="sidebar-pricing-link" className="c-sidebar-nav-item">
        <SidebarLink
          className={
            "c-sidebar-nav-link" +
            (window.location.pathname.startsWith("/pricing") ? " active" : "")
          }
          to={linksDisabled ? "#" : "/pricing"}
        >
          <i className="c-sidebar-nav-icon fa fa-credit-card"></i> Pricing
        </SidebarLink>
      </li>
      <li key="sidebar-support-title" className="c-sidebar-nav-title">
        Support
      </li>
      <li key="sidebar-chat-link" className="c-sidebar-nav-item">
        <SidebarLink
          className={
            "c-sidebar-nav-link" +
            (window.location.pathname.startsWith("/chat") ? " active" : "")
          }
          to={
            linksDisabled
              ? "#"
              : {
                  pathname: `https://go.crisp.chat/chat/embed/?website_id=${window.CRISP_WEBSITE_ID}`,
                }
          }
          target={linksDisabled ? null : "_blank"}
          rel="nofollow"
        >
          <i className="c-sidebar-nav-icon fa fa-comment-alt"></i> Live Chat
        </SidebarLink>
      </li>
      <li key="sidebar-contact-link" className="c-sidebar-nav-item">
        <SidebarLink
          className={
            "c-sidebar-nav-link" +
            (window.location.pathname.startsWith("/contact") ? " active" : "")
          }
          to={
            linksDisabled
              ? "#"
              : {
                  pathname:
                    "mailto:%73%75%70%70%6f%72%74%40%6c%65%63%74%6f%2e%61%69",
                }
          }
          target={linksDisabled ? null : "_blank"}
          rel="nofollow"
        >
          <i className="c-sidebar-nav-icon fa fa-envelope"></i> Contact Us
        </SidebarLink>
      </li>
      <li key="sidebar-status-link" className="c-sidebar-nav-item">
        <SidebarLink
          className={
            "c-sidebar-nav-link" +
            (window.location.pathname.startsWith("/status") ? " active" : "")
          }
          to={
            linksDisabled
              ? "#"
              : {
                  pathname: "https://lecto.statuspage.io/",
                }
          }
          target={linksDisabled ? null : "_blank"}
          rel="nofollow"
        >
          <i className="c-sidebar-nav-icon fa fa-info"></i> System Status
        </SidebarLink>
      </li>
      <li key="sidebar-legal-title" className="c-sidebar-nav-title">
        Legal Stuff
      </li>
      <li key="sidebar-tos-link" className="c-sidebar-nav-item">
        <SidebarLink
          className={
            "c-sidebar-nav-link" +
            (window.location.pathname.startsWith("/terms") ? " active" : "")
          }
          to={linksDisabled ? "#" : "/terms"}
        >
          <i className="c-sidebar-nav-icon fa fa-gavel"></i> Terms of Use /
          Privacy Policy
        </SidebarLink>
      </li>
    </ul>
  );
};

export default AppMenu;
