import React, { useState } from "react";
import PublicUserMenu from "../../menus/PublicUserMenu";
import AppMenu from "../../menus/AppMenu";
import Logo from "../../Logo";
import { BreadcrumbContext } from "../../Breadcrumb";

export const SidebarContext = React.createContext();

const PublicTemplate = ({ children }) => {
  // eslint-disable-next-line
  const [breadcrumb, setBreadcrumb] = useState([]); // breadcrumb used from template in children
  const [sidebar, setSidebar] = useState("enabled"); // sidebar enabled/disabled used from template in children

  return (
    <div className="c-app">
      <div
        className={
          "c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show" +
          (window.location.pathname.startsWith("/pricing")
            ? " c-sidebar-minimized"
            : "")
        }
        id="sidebar"
      >
        <div className="c-sidebar-brand d-md-down-none">
          <Logo />
        </div>
        <AppMenu linksDisabled={sidebar === "disabled" ? true : false} />
        <button
          className="c-sidebar-minimizer c-class-toggler"
          data-target="_parent"
          data-class="c-sidebar-minimized"
          type="button"
          disabled={sidebar === "disabled"}
        />
      </div>

      <div className="c-wrapper">
        <header className="c-header c-header-light c-header-fixed">
          <button
            className="c-header-toggler c-class-toggler d-lg-none mfe-auto"
            type="button"
            data-target="#sidebar"
            data-class="c-sidebar-show"
          >
            <i className="mt-3 fa fa-bars" />
          </button>
          <div className="mfe-auto"></div>
          <ul className="c-header-nav">
            <PublicUserMenu />
          </ul>
        </header>
        <div className="c-body">
          <main className="c-main">
            <SidebarContext.Provider value={{ setSidebar }}>
              <BreadcrumbContext.Provider value={{ setBreadcrumb }}>
                {children}
              </BreadcrumbContext.Provider>
            </SidebarContext.Provider>
          </main>
        </div>
      </div>
    </div>
  );
};

export default PublicTemplate;
